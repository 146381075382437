import React from "react";
import { ClubLogo } from "../../ClubLogo";

export default function FooterLiveMatches({
                                            match_id,
                                            matchTime,
                                            homeTeamName,
                                            awayTeamName,
                                            videoUrl,
                                          }) {
  const handleMatchClick = (matchId, localTime) => {
    if (localTime === "LIVE") {
      window.location = "/matchdetail?id=" + matchId;
    }
  };

  return (
      <div
          className={`MatchCard ${
              matchTime === "LIVE" ? "cursor-pointer" : ""
          } relative w-full`}
          onClick={() => handleMatchClick(match_id, matchTime)}
      >
        <div className={`schedule ${matchTime === "LIVE" ? "live" : ""}`}>
          {matchTime}
          {matchTime === "LIVE" ? (
              <div className="live-indicator mr-[10px]">
                <div className="inner"></div>
              </div>
          ) : null}
        </div>
        <div className="name text-[11px] md:max-w-[60%] text-white font-chakra leading-[14px] max-w-[80%] md:overflow-visible md:whitespace-normal truncate">
          {homeTeamName} v {awayTeamName}
        </div>
        <div className="flex absolute md:top-[-30px] right-0 top-[-20px]">
          <div className="homeTeam md:w-[42px] md:h-[42px] w-[32px] h-[32px] mr-[-5px]">
            <ClubLogo teamName={homeTeamName} />
          </div>
          <div className="awayTeam md:w-[42px] md:h-[42px] w-[32px] h-[32px] ml-[-5px]">
            <ClubLogo teamName={awayTeamName} />
          </div>
        </div>
        {/* <div className="absolute md:right-[-30px] md:bottom-[-50px] right-[-8px] bottom-[-50px] md:w-[100px] md:h-[57px] w-[80px] h-[45px]">
        <iframe width="100%" height="100%" title="footerVideo" src={videoUrl}></iframe>
      </div> */}
      </div>
  );
}
