import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  className: any;
  to: string;
}

export const LogoWrapper = ({ className, to }: Props): JSX.Element => {
  return (
    <Link
      className={`w-[300px] h-[300px] bg-[url(https://dyatkw7msrzcb.cloudfront.net/assets/image-1.png)] bg-cover bg-[50%_50%] ${className}`}
      to={to}
    />
  );
};

LogoWrapper.propTypes = {
  to: PropTypes.string,
};
